<template>
    <div class="reset-init-index">
        <div class="reset-init">
            <picture class="reset-init__image">
                <img :src="$assets.auth.recovery.success2" alt="image" />
            </picture>
            <h2 class="reset-init__title">Todo listo</h2>
            <p class="reset-init__subtitle">Revisa tu correo y sigue las instrucciones</p>
        </div>
    </div>
</template>

<script lang="ts" setup>
definePageMeta({
    middleware: ['not-auth-required'],
})

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()
const { $lang } = useNuxtApp()

const title = 'Cambiar contraseña | ' + $lang.brand
const description = 'Página para cambiar la contraseña'

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.reset-init-index {
    @apply container pb-5 lg:pb-10;

    .reset-init {
        @apply relative mx-auto mt-3 max-w-xl space-y-3 rounded-lg bg-white bg-opacity-95 p-5 xs:mt-20;
        &__image {
            @apply block h-40;
            img {
                @apply mx-auto h-full;
            }
        }
        &__title {
            @apply text-center text-3xl font-medium text-gray-800;
        }
        &__subtitle {
            @apply text-center text-xl text-gray-800;
        }
    }
}
</style>
